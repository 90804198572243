import React, {useEffect, useState} from "react";
import {Card} from "react-bootstrap";
import {Photo, User} from "../types/Types";
import {collection, getDocs, limit, query, where} from "@firebase/firestore";
import {db, functions} from "../../firebase/FirebaseProvider";
import {generateTags, PHOTOS_LIMIT} from "../util/Util";
import PhotoRow from "../locations/profile/PhotoRow";
import {httpsCallable} from "@firebase/functions";
import AuthCheck from "../auth/AuthCheck";
import PageTracking from "../auth/PageTracking";
import PhotoTag from "../tag/PhotoTag";
import LabelTag from "../tag/LabelTag";

interface Props {
    match: {
        params: {
            id: string
        }
    },
    currentUser: User | undefined,
    usersMap: Map<string, User>,
}

function PhotoEditor(props: Props) {
    const [photo, setPhoto] = useState<Photo>();
    const [newTag, setNewTag] = useState<string>("");

    useEffect(() => {
        (async () => {
            await loadPhoto();
        })();
    }, [props.currentUser]);

    const loadPhoto = async() => {
        if(!props.currentUser) {
            return
        }
        const photosOnceCollection = query(collection(db, 'photos'),
            where('id', '==', props.match.params.id),
            limit(PHOTOS_LIMIT));
        const photosOnceCollectionSnapshot = await getDocs(photosOnceCollection);
        photosOnceCollectionSnapshot.forEach((doc) => {
            let tempPhoto = doc.data() as Photo;
            tempPhoto.id = doc.id;
            if(props.currentUser!.photoAdmin || tempPhoto.userId == props.currentUser!.id) {
                setPhoto(tempPhoto);
            } else {
                window.location.assign(window.location + "");
            }
        });
    };

    const hidePhoto = (photoId: string): void => {
        const hidePhoto = httpsCallable(functions, 'hidePhoto');
        hidePhoto(
            {
                photoId
            }
        ).then(r => {
            window.location.assign('/hub/photos');
        });
    };

    const addTags = (photoId: string): void => {
        const addPhotoTags = httpsCallable(functions, 'addPhotoTags');
        addPhotoTags(
            {
                photoId: photoId,
                tags: generateTags(newTag)
            }
        ).then(r => {
            window.location.assign(window.location + "");
        });
    };

    const clearTags = (photoId: string): void => {
        const clearPhotoTags = httpsCallable(functions, 'clearPhotoTags');
        clearPhotoTags(
            {
                photoId: photoId,
                tags: []
            }
        ).then(r => {
            window.location.assign(window.location + "");
        });
    };

    return (
        <>
            <PageTracking />
            <AuthCheck />
            <PageTracking />
            <Card className={"mb-3"}>
                <div className={"album"}>
                    {!photo && <div className={'loading'}>
                        Loading...
                    </div>}
                    {(photo && props.currentUser) && <PhotoRow
                        currentUser={props.currentUser}
                        photo={photo}
                        photoUser={props.usersMap.get(photo.userId)}
                        displayLocation={true}
                        displayEditButton={false}
                    />}
                </div>
            </Card>
            {(photo && photo!.tags.length > 0) && <Card className={"mb-3"}>
                <Card.Body>
                    <div className={"d-inline-block mr-1"}><LabelTag/></div>
                    {photo!.tags.map((tag) => (
                        <div className={"d-inline-block mr-1"}><PhotoTag name={tag} count={0} bold={false} /></div>
                    ))}
                </Card.Body>
            </Card>}
            {photo && <Card className={"mb-3"}>
                <Card.Body>
                    <div className={"mb-3"}>
                        <input type={"text"}
                               placeholder={"E.g. carpet, frontage, menu"}
                               className="form-control"
                               value={newTag}
                               id="tags"
                               style={{minWidth: "200px"}}
                               onChange={e => setNewTag(e.target.value)}>
                        </input>
                    </div>
                    <div className={"d-inline-block float-right"}>
                        {props.currentUser && <div className={"float-right"}>
                            <button className={"btn btn-primary"} onClick={() => {addTags(photo!.id)}}>Add tags</button>
                        </div>}
                    </div>
                    <div className={"d-inline-block float-right mr-2"}>
                        {props.currentUser && <div className={"float-right"}>
                            <button className={"btn btn-danger"} onClick={() => {clearTags(photo!.id)}}>Clear tags</button>
                        </div>}
                    </div>
                </Card.Body>
            </Card>}
            {photo && <Card className={"mb-3"}>
                <Card.Body>
                    {props.currentUser && <div className={"float-right"}>
                        <button className={"btn btn-danger"} onClick={() => {hidePhoto(photo!.id)}}>Delete photo</button>
                    </div>}
                </Card.Body>
            </Card>}
        </>
    )
}

export default PhotoEditor;