import React from "react";
import "../nav/Navigation.css";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPen} from "@fortawesome/free-solid-svg-icons";

interface Props {
  photoId: string,
  displayEditButton: boolean,
}

function EditPhotoTag(props: Props) {

    return (
        <>
          {props.displayEditButton && <Link to={"/photo/" + props.photoId}>
              <div className={`edit-tag d-inline-block`}>
                <span style={{fontSize: "0.7em"}}><FontAwesomeIcon className="ml-0 animated zoomIn" icon={faPen} /></span>
              </div>
            </Link>}
        </>
    )
}

export default EditPhotoTag;
