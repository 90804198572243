import React from "react";
import "../nav/Navigation.css";
import {Link} from "react-router-dom";
import {getDateFromTimeStamp} from "../util/Util";
import VerificationTag from "./VerificationTag";
import {Location, Session} from "../types/Types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faStar} from "@fortawesome/free-solid-svg-icons";

interface Props {
  name: string;
  id: string;
  session: Session | undefined;
  displayVerificationTag: boolean;
  linksToTab: String;
}

function LocationTag(props: Props) {

  const isCheckedIn = (props.session && getDateFromTimeStamp(props.session.checkOutTime) > new Date()) || false;

  return (
      <div className={`d-inline-block`}>
        <div className={`d-inline-block mr-1`}>
          <Link to={`/location/${props.id}/${props.linksToTab}`} className={"p-0"}>
            <div className={`location-tag ${isCheckedIn ? "active-session" : ""}`}>{props.name}</div>
          </Link>
        </div>
        {(isCheckedIn || props.displayVerificationTag) && <div className={"d-inline-block"}>
            <VerificationTag active={isCheckedIn}/>
        </div>}
        {(props.session && props.session.bonus) && <small className="text-truncate">
            <FontAwesomeIcon className="ml-1 animated zoomIn icon-bonus" icon={faStar} />
        </small>}
      </div>
    )
}

export default LocationTag;
