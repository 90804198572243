import React, {useEffect, useState} from "react";
import {Card, ListGroup} from "react-bootstrap";
import {Photo, Session, User} from "../types/Types";
import {collection, getDocs, limit, orderBy, query, where} from "@firebase/firestore";
import {db} from "../../firebase/FirebaseProvider";
import HubNav from "./HubNav";
import {getDateFromTimeStamp, PHOTOS_LIMIT} from "../util/Util";
import UserTag from "../users/tag/UserTag";
import PhotoTag from "../tag/PhotoTag";
import DateTime from "../date/DateTime";
import LocationTag from "../tag/LocationTag";
import PageTracking from "../auth/PageTracking";
import LabelTag from "../tag/LabelTag";
import {Link} from "react-router-dom";

interface Props {
    usersMap: Map<string, User>;
    session: Session | undefined;
    currentUser: User | undefined;
}

export interface PhotoTagDetails {
    tagName: string;
    tagCount: number;
}

function Photos(props: Props) {

    const [photos, setPhotos] = useState<Array<Photo> | undefined>(Array());
    const [tags, setTags] = useState<Array<PhotoTagDetails>>(Array());

    useEffect(() => {
        (async () => {
            await loadAllData();
        })();
    }, [props.currentUser]);

    const loadAllData = async() => {
        let photos: Array<Photo> = [];
        const photosOnceCollection = query(collection(db, 'photos'),
            where('hidden', '==', false),
            orderBy('createdAt', 'desc'),
            limit(PHOTOS_LIMIT));
        const photosOnceCollectionSnapshot = await getDocs(photosOnceCollection);
        photosOnceCollectionSnapshot.forEach((doc) => {
            let tempPhoto = doc.data() as Photo;
            photos.push(tempPhoto);
        });
        setPhotos(photos);
    };

    useEffect(() => {
        (async () => {
            if(!photos) {
                return
            }
            const tagCount: { [key: string]: number } = {};
            photos.forEach(photo => {
                photo.tags.forEach(tag => {
                    if (tag in tagCount) {
                        tagCount[tag]++;
                    } else {
                        tagCount[tag] = 1;
                    }
                });
            });
            const sortedTags = Object.entries(tagCount)
                .sort((a, b) => b[1] - a[1])
                .map(entry => (
                    {tagName: entry[0], tagCount: entry[1]} as PhotoTagDetails
                ));
            setTags(sortedTags);
        })();
    }, [photos]);

    const isCheckedIn = props.session && getDateFromTimeStamp(props.session.checkOutTime) > new Date() || false;

    return (
        <>
            <PageTracking />
            <HubNav currentUser={props.currentUser} />
            {tags.length > 0 && <Card className={"mb-2"}>
                <div className={"card-alt-padding"}>
                    <Link to={"/hub/photos"}>
                        <div className={"d-inline-block"}>
                            <LabelTag/>
                        </div>
                    </Link>
                    <div className={"d-inline-block ml-1 mr-1"}>
                        {photos && <PhotoTag name={"all"} count={photos.length} bold={true} />}
                    </div>
                    {tags.map((tag) => (
                        <div className={"d-inline-block mr-1"}>
                            <PhotoTag name={tag.tagName} count={tag.tagCount} bold={true} />
                        </div>
                    ))}
                </div>
            </Card>}
            <Card className={"mb-3"}>
                <ListGroup variant="flush">
                    {!photos && <div className={'loading'}>
                        Loading...
                    </div>}
                    {(photos && photos.length == 0) &&<div className={'loading'}>
                        No photos yet
                    </div>}
                    {photos && photos.map((photo: Photo, index: number) => (
                        <>
                            {<div className={"news-item"}>
                                {/*<div className={"d-inline-block mr-2"}>*/}
                                {/*    <div style={{marginLeft: "0.2em", marginRight: "0.2em", marginBottom: "0.2em", marginTop: "0.2em"}} className={"d-inline-block"}>*/}
                                {/*        <img className={"super-mini-photo"} src={photo.smallSizeUrl}/>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                <div className={"d-inline-block"}>
                                    <div className={"d-inline-block"}>
                                        <UserTag user={props.usersMap.get(photo.userId)} visit={undefined} session={undefined} padRight={false} hideLocationDot={false} linksToTab={"photos"}/>
                                    </div>
                                    <div className={"d-inline-block mr-1"}>
                                        added a photo to
                                    </div>
                                    <LocationTag name={photo.locationName} id={photo.locationId} session={props.session} displayVerificationTag={isCheckedIn} linksToTab={"photos"} />
                                    {photo.tags.length > 0 && <div className={`d-inline-block mr-1 ${isCheckedIn ? 'ml-1' : ''}`}>
                                        tagged with
                                    </div>}
                                    {photo.tags.map((tag) => (
                                        <div className={"d-inline-block mt-1 mr-1"}><PhotoTag name={tag} count={0} bold={false} /></div>
                                    ))}
                                </div>

                                <DateTime date={photo.createdAt}/>
                            </div>}
                        </>
                    ))}
                </ListGroup>
            </Card>
        </>
    )
}

export default Photos;