import React from "react";
import "../nav/Navigation.css";
import {Link} from "react-router-dom";

interface Props {
  name: string;
  count: number;
  bold: boolean;
}

function PhotoTag(props: Props) {

    return (
        <>
            <Link to={"/photos/" + props.name}>
              <div className={`user-tag d-inline-block`}>
                <span className={`${props.bold ? "bold-text" : ""}`}>{props.name}</span> {props.count > 0 && <span style={{fontSize: "0.7em"}}>{props.count}</span>}
              </div>
            </Link>
        </>
    )
}

export default PhotoTag;
