import React, {useEffect, useState} from "react";
import {Card} from "react-bootstrap";
import {Photo, User} from "../types/Types";
import {collection, getDocs, limit, orderBy, query, where} from "@firebase/firestore";
import {db} from "../../firebase/FirebaseProvider";
import {PHOTOS_LIMIT} from "../util/Util";
import PhotoRow from "../locations/profile/PhotoRow";
import PhotoTag from "../tag/PhotoTag";
import AuthCheck from "../auth/AuthCheck";
import PageTracking from "../auth/PageTracking";
import LabelTag from "../tag/LabelTag";
import {Link} from "react-router-dom";

interface Props {
    match: {
        params: {
            tag: string
        }
    },
    currentUser: User | undefined
    usersMap: Map<string, User>
}

function PhotosViewer(props: Props) {

    const [photos, setPhotos] = useState<Array<Photo> | undefined>(undefined);

    useEffect(() => {
        (async () => {
            await loadPhotos();
        })();
    }, []);

    const loadPhotos = async() => {
        // Load photos
        let photos: Array<Photo> = [];
        const photosOnceCollection = query(collection(db, 'photos'),
            where('hidden', '==', false),
            orderBy('createdAt', 'desc'),
            limit(PHOTOS_LIMIT));
        const photosOnceCollectionSnapshot = await getDocs(photosOnceCollection);
        photosOnceCollectionSnapshot.forEach((doc) => {
            let tempPhoto = doc.data() as Photo;
            tempPhoto.id = doc.id;
            photos.push(tempPhoto);
        });
        setPhotos(photos);
    };

    const filteredPhotos = () => {
        if(!photos) {
            return []
        }
        if(props.match.params.tag !== "all") {
            return photos.filter((photo) => {
                return photo.tags.includes(props.match.params.tag)
            });
        } else {
            return photos;
        }
    };

    return (
        <>
            <PageTracking />
            <AuthCheck />
            <Card className={"mb-2"}>
                <div className={"card-alt-padding"}>
                    <Link to={"/hub/photos"}>
                        <div className={"d-inline-block"}>
                            <LabelTag/>
                        </div>
                    </Link>
                    <div className={"ml-1 d-inline-block"}>
                        <PhotoTag name={props.match.params.tag} count={0} bold={true} />
                    </div>
                </div>
            </Card>
            <Card className={"mb-3"}>
                <div>
                    {!photos && <div className={'loading'}>
                        Loading...
                    </div>}
                    {(photos && filteredPhotos().length == 0) &&<div className={'loading'}>
                        No photos yet
                    </div>}
                    <div className={"album"}>
                        {photos && filteredPhotos()
                            .map((photo: Photo, index: number) => (
                            props.currentUser && <PhotoRow
                                key={photo.id + index}
                                currentUser={props.currentUser}
                                photo={photo}
                                photoUser={props.usersMap.get(photo.userId)}
                                displayLocation={true}
                                displayEditButton={true}
                            />
                        ))}
                    </div>
                </div>
            </Card>
        </>
    )
}

export default PhotosViewer;