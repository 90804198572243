import {
  faCog, faLocationDot, faMessage,
  faSignInAlt,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {useEffect, useState} from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";
import history from "../util/History";
import {Message, Notifications, Session, SettingsDoc, User} from "../types/Types";
import {getAuth, signOut} from "firebase/auth";
import UserTag from "../users/tag/UserTag";
import {isChatEnabled} from "../util/Util";
import PageTracking from "../auth/PageTracking";
import AuthCheck from "../auth/AuthCheck";
import {collection, limit, onSnapshot, orderBy, query, where} from "@firebase/firestore";
import {db} from "../../firebase/FirebaseProvider";

interface Props {
  session: Session | undefined;
  sessions: Array<Session> | undefined;
  notifications: Notifications | undefined;
  currentUser: User | undefined;
  setCurrentUser: (user: User | undefined) => void;
  settings: SettingsDoc | undefined;
}

function Header(props: Props) {
  const [shouldNotify, setShouldNotify] = useState(false);

  const checkNotification = (): boolean => {
    if(window.location.href.includes("chat")) {
      return false
    }
    if(props.currentUser == undefined) {
      return false
    }
    if(props.notifications == undefined || props.currentUser == undefined) {
      return false
    }
    if(props.notifications.lastMessage == undefined) {
      return false
    }
    if(props.currentUser.messageChannels == undefined) {
      return true
    }
    return props.notifications.lastMessage && props.notifications.lastMessage > props.currentUser.messageChannels.lastSeen;
  };

  useEffect(() => {
      setShouldNotify(checkNotification)
  }, [props.notifications, props.currentUser]);

  const logout = () => {
    const auth = getAuth();
    signOut(auth).then(() => {
      props.setCurrentUser(undefined);
      history.push("/");
    });
  };

  return (
    <>
      <PageTracking />
      <AuthCheck />
      <Navbar expand variant="dark" sticky="top" bg="dark" style={{maxHeight: "70px"}}>
        <Container>
          <div className={"logo"}>
            <Navbar.Brand style={{ fontSize: "10pt", margin: "0"}} href="/home">'Spoons<br/>Tracker</Navbar.Brand>
          </div>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              {(props.currentUser && !props.currentUser.socialDisabled && props.sessions && props.sessions.length > 0) && (
                  <Nav.Item style={{marginRight: "-8px", paddingTop: "7px", paddingBottom: "0px"}}>
                    <Link to={`/hub/sessions`} className="nav-link cursor-pointer">
                      <div className={`user-tag active-session`}>
                        <FontAwesomeIcon icon={faLocationDot} />
                        <div className={"d-inline-block ml-1"}>{props.sessions.length}</div>
                      </div>
                    </Link>
                  </Nav.Item>
              )}
              {props.currentUser && (
                <Nav.Item style={{paddingTop: "7px", paddingBottom: "0px"}}>
                  <Link to={`/profile/${props.currentUser.id}/visits/me`} className="nav-link cursor-pointer" style={{paddingBottom: "3px"}}>
                    <div>
                      <UserTag visit={undefined} session={props.session} user={props.currentUser} padRight={false} hideLocationDot={true} linksToTab={"visits/me"} />
                    </div>
                  </Link>
                  <div style={{float: "right", paddingRight: "3px"}}>
                    {/*{this.props.currentUser && <div className={"d-inline-block"} style={{color: "rgba(255, 255, 255, 0.6)"}}>*/}
                    {/*    <div className={"d-inline-block mr-1"}>{this.props.currentUser.visitCount} - {this.props.currentUser.sessionVerifiedCount}<small><FontAwesomeIcon className={"ml-1"} icon={faLocationDot} /></small>*/}
                    {/*    </div>*/}
                    {/*</div>}*/}
                    {isChatEnabled(props.currentUser, props.settings) && (
                        <Nav.Item className={"d-inline-block"} style={{paddingTop: "0px"}}>
                          <Link to={`/chat/general`} className="nav-link cursor-pointer" style={{paddingTop: "0px"}}>
                            <FontAwesomeIcon
                                style={{color: shouldNotify ? "white" : "rgba(255, 255, 255, 0.6)"}}
                                icon={faMessage}
                            />
                          </Link>
                        </Nav.Item>
                    )}
                    {props.currentUser && (
                        <Nav.Item className={"d-inline-block"} style={{paddingTop: "0px"}}>
                          <Link to={`/settings`} className="nav-link cursor-pointer" style={{paddingTop: "0px"}}>
                            <FontAwesomeIcon icon={faCog} />
                          </Link>
                        </Nav.Item>
                    )}
                    {props.currentUser && (
                        <Nav.Item className={"d-inline-block"} style={{paddingTop: "0px"}}>
                          <Button
                              onClick={logout}
                              className="nav-link"
                              variant="link"
                              style={{paddingTop: "0px"}}
                          >
                            <FontAwesomeIcon icon={faSignOutAlt} />
                          </Button>
                        </Nav.Item>
                    )}
                  </div>
                </Nav.Item>
              )}
              {!props.currentUser && (
                <Nav.Item>
                  <Link to="/" className="nav-link">
                    Login <FontAwesomeIcon icon={faSignInAlt} />
                  </Link>
                </Nav.Item>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default Header;
