import React, {useEffect, useState} from "react";
import {db} from '../../../firebase/FirebaseProvider'
import {
    doc,
    collection,
    getDoc,
    getDocs,
    query,
    orderBy,
    where,
    limit,
} from 'firebase/firestore'
import {Location, Session, User} from "../../types/Types";
import {Card, ListGroup} from "react-bootstrap";
import UserDetails from "../UserDetails";
import UserNav from "../UserNav";
import {NOTIFICATION_LIMIT} from "../../util/Util";
import SessionRow from "../../shared/SessionRow";
import PageTracking from "../../auth/PageTracking";

interface Props {
    match: {
        params: {
            id: string
        }
    },
    locations: Array<Location> | undefined,
    currentUser: User | undefined,
}

function UserSessions(props: Props) {

    const [sessions, setSessions] = useState<Array<Session> | undefined>(undefined);
    const [user, setUser] = useState<User | undefined>(undefined);

    useEffect(() => {
        (async () => {
            const userRef = doc(db, 'users', props.match.params.id);
            const docSnap = await getDoc(userRef);
            if (docSnap.exists()) {
                let userToSet = docSnap.data() as User;
                userToSet.id = docSnap.id;
                setUser(userToSet);
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            await loadAllData();
        })();
    }, [user]);

    const loadAllData = async() => {
        if(!user) {
            return;
        }
        // Load user sessions
        let sessions: Array<Session> = [];
        const sessionOnceCollection = query(collection(db, 'sessions'),
            where('userId', '==', user.id),
            orderBy('checkInTime', 'desc'),
            limit(2000));
        const sessionOnceCollectionSnapshot = await getDocs(sessionOnceCollection);
        sessionOnceCollectionSnapshot.forEach((doc) => {
            let session = doc.data() as Session;
            sessions.push(session);
        });
        setSessions(sessions);
    };

    return (
        <>
            <PageTracking />
            <UserDetails user={user}/>
            <UserNav currentUser={props.currentUser} user={user}/>
            <Card className={"mb-3"}>
                {sessions && sessions.length > 0 && <Card.Header>
                    <div className={"d-inline-block float-left"}>
                        Total: <b>{sessions.length}</b>
                    </div>
                </Card.Header>}
                <ListGroup variant="flush">
                    {sessions && sessions.map((session: Session, index: number) => (
                        <SessionRow displayBonus={false} displayName={false} displayLocation={true} session={session} user={user}/>
                    ))}
                    {!sessions && <>
                        <div className={"loading"}>Loading...</div>
                    </>}
                    {(sessions && sessions.length == 0) &&<div className={'loading'}>
                        No check-ins yet
                    </div>}
                </ListGroup>
            </Card>
        </>
    );
}

export default UserSessions;