import React, {useEffect, useState} from "react";
import {Card} from "react-bootstrap";
import {Location, Photo, SettingsDoc, User} from "../../types/Types";
import {collection, doc, getDoc, getDocs, limit, orderBy, query, where} from "@firebase/firestore";
import {db, functions, storage} from "../../../firebase/FirebaseProvider";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import LocationNav from "./LocationNav";
import LocationDetails from "./LocationDetails";
import {generateTags, generateUUID, isPhotoEnabled, PHOTOS_LIMIT, resizeImage} from "../../util/Util";
import LocationCheckIn from "./LocationCheckIn";
import PhotoRow from "./PhotoRow";
import {httpsCallable} from "@firebase/functions";
import PageTracking from "../../auth/PageTracking";

interface Props {
    match: {
        params: {
            id: string
        }
    },
    currentUser: User | undefined
    usersMap: Map<string, User>
    settings: SettingsDoc
}

function LocationPhotos(props: Props) {

    const [location, setLocation] = useState<Location | undefined>(undefined);
    const [fullSizePhotoToUpload, setFullSizePhotoToUpload] = useState<Blob | undefined>();
    const [smallSizePhotoToUpload, setSmallSizePhotoToUpload] = useState<Blob | undefined>();
    const [displayPhoto, setDisplayPhoto] = useState<string | undefined>();
    const [uploadingPhoto, setUploadingPhoto] = useState<boolean>(false);
    const [feedback, setFeedback] = useState<string | undefined>();
    const [tags, setTags] = useState<string>("");
    const [photos, setPhotos] = useState<Array<Photo> | undefined>(undefined);

    useEffect(() => {
        (async () => {
            const locationRef = doc(db, 'locations', props.match.params.id);
            const docSnap = await getDoc(locationRef);
            if (docSnap.exists()) {
                let locationToSet = docSnap.data() as Location;
                locationToSet.id = docSnap.id;
                setLocation(locationToSet);
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            await loadPhotos();
        })();
    }, [location]);

    const loadPhotos = async() => {
        if(!location) {
            return;
        }

        // Load photos
        let photos: Array<Photo> = [];
        const photosOnceCollection = query(collection(db, 'photos'),
            where('locationId', '==', location.id),
            where('hidden', '==', false),
            orderBy('createdAt', 'desc'),
            limit(PHOTOS_LIMIT));
        const commentsOnceCollectionSnapshot = await getDocs(photosOnceCollection);
        commentsOnceCollectionSnapshot.forEach((doc) => {
            let tempPhoto = doc.data() as Photo;
            tempPhoto.id = doc.id;
            photos.push(tempPhoto);
        });
        setPhotos(photos);
    };

    const preparePhotoForUpload = (e: any) => {
        if(!e.target.files[0]) {
            return;
        }

        let fileSize = (e.target.files[0].size) / 1024 / 1024;
        if (fileSize > 30) {
            alert('File size exceeds 30 MB');
        } else {
            const fullSizePhoto = e.target.files[0];
            const displayPhoto = URL.createObjectURL(fullSizePhoto);

            setFullSizePhotoToUpload(fullSizePhoto);
            setDisplayPhoto(displayPhoto);

            resizeImage(fullSizePhoto, 720, 540)!.then((resizedImage) => {
                setSmallSizePhotoToUpload(resizedImage as Blob)
            }).catch(function (err) {
                console.error(err);
            });
        }
    };

    const savePhoto = async (e: any) => {
        e.preventDefault();
        if (!e) {
            return;
        }
        if(!fullSizePhotoToUpload && !smallSizePhotoToUpload) {
            alert("Please attach a photo.");
            return;
        }
        setUploadingPhoto(true);
        await uploadPhotos(generateUUID(), generateUUID());
        setUploadingPhoto(false);
        setDisplayPhoto(undefined);
        setTags("");
    };

    const uploadPhotos = async (fullSizeId: string, smallSizeId: string) => {
        if(!location || !fullSizePhotoToUpload || !smallSizePhotoToUpload) {
            return
        }

        const fullSizeStorageRef = ref(storage, 'photos/locations/' + location.id + '/' + fullSizeId);
        const smallSizeStorageRef = ref(storage, 'photos/locations/' + location.id + '/thumbnails/' + smallSizeId);

        await uploadBytes(fullSizeStorageRef, fullSizePhotoToUpload);
        const fullSizeUrl = await getDownloadURL(fullSizeStorageRef);
        await uploadBytes(smallSizeStorageRef, smallSizePhotoToUpload);
        const smallSizeUrl = await getDownloadURL(smallSizeStorageRef);

        storePhotoMetaData(fullSizeUrl, fullSizeId, smallSizeUrl, smallSizeId);
    };

    const storePhotoMetaData = (fullSizeUrl: string, fullSizeId: string, smallSizeUrl: string, smallSizeId: string) => {
        if(!location) {
            return
        }
        try {
            const savePhoto = httpsCallable(functions, 'savePhoto');
            savePhoto({
                photo: {
                    fullSizeUrl: fullSizeUrl,
                    fullSizeId: fullSizeId,
                    smallSizeUrl: smallSizeUrl,
                    smallSizeId: smallSizeId,
                    locationId: location.id,
                    locationName: location.name,
                    tags: generateTags(tags),
                }
            }).then(r => {
                window.location.assign(window.location + "");
            });
        } catch(ex) {
            console.log("An error occurred saving the photo: " + ex);
        }
    };

    return (
        <>
            <PageTracking />
            {props.currentUser && <LocationDetails location={location} currentUser={props.currentUser} />}
            {location && props.currentUser && !props.currentUser.socialDisabled && <LocationCheckIn currentUser={props.currentUser} location={location}/>}
            {location && <LocationNav settings={props.settings} currentUser={props.currentUser} locationId={location.id}/>}

            {location && props.currentUser && !props.currentUser.socialDisabled && isPhotoEnabled(props.currentUser, props.settings) && <Card className={"mb-2"}>
                <div style={{padding: "0.8em", paddingTop: "1em"}}>

                    <div className={"photo-display"}>
                        <div className={""}>
                            <div className={""} style={{border: "none"}}>
                                {!displayPhoto && <label className={"photo-upload-label"} htmlFor="image-input">Attach<br />Photo</label>}
                                {displayPhoto &&  <label className={""} htmlFor="image-input">
                                    <img id="img-preview" className={"thumbnail"} src={displayPhoto}/>
                                </label>}
                            </div>
                        </div>
                    </div>

                    <div className="d-inline-block ml-2">
                        <div>
                            <input onChange={preparePhotoForUpload}
                                   type="file"
                                   className="input-file d-none"
                                   name="image"
                                   id="image-input"/>
                        </div>
                        <div className={"mt-1"}>
                            <label>Add a description tag</label>
                            <input type={"text"}
                                   placeholder={"E.g. carpet, frontage, menu"}
                                   className="form-control"
                                   value={tags}
                                   id="tags"
                                   style={{minWidth: "200px"}}
                                   onChange={e => setTags(e.target.value)}>
                            </input>
                        </div>
                    </div>

                    <div className="form-group p-0">
                        <div>
                            <div style={{float: "left", marginTop: "0.6em"}}>
                                <span>{feedback}</span>
                            </div>
                            <div className={""} style={{float: "right", marginTop: "0.6em"}}>
                                <button disabled={uploadingPhoto} onClick={(e) => savePhoto(e)} className={"btn btn-primary btn-sm"}>
                                    {!uploadingPhoto && <span>Save</span>}
                                    {uploadingPhoto && <span>Saving...</span>}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Card>}

            <Card className={"mb-3"}>
                <div>
                    {!photos && <div className={'loading'}>
                        Loading...
                    </div>}
                    {(photos && photos.length == 0) &&<div className={'loading'}>
                        No photos yet
                    </div>}
                    <div className={"album"}>
                        {photos && photos
                            .map((photo: Photo, index: number) => (
                            props.currentUser && <PhotoRow
                                key={photo.id + index}
                                currentUser={props.currentUser}
                                photo={photo}
                                photoUser={props.usersMap.get(photo.userId)}
                                displayLocation={false}
                                displayEditButton={true}
                            />
                        ))}
                    </div>
                </div>
            </Card>
        </>
    )
}

export default LocationPhotos;